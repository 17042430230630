import React, { useEffect, useMemo } from "react";

import { Helmet } from "react-helmet-async";
import { useCallOffs } from "@hooks/useApiData";
import { getCallOffHref, paths } from "@src/paths";
import { Status, statusDetails } from "@src/types";
import SusTabBox from "@components/SusTabBox/SusTabBox";
import ModuleOverviewTable from "@components/ModuleOverviewTable/ModuleOverviewTable";
import { useDashboardState } from "@src/app.state";

const List: React.FC = () => {
  const {
    setBreadcrumbs,
    viewSettings: { apiVersion, callOff: callOffViewSettings },
  } = useDashboardState();

  const {
    data: callOffs,
    totalCount: callOffTotalCount,
    loading,
  } = useCallOffs(apiVersion, { limit: 200 });

  useEffect(
    () => setBreadcrumbs([{ label: callOffViewSettings.titlePlural, url: paths.callOffOverview }]),
    []
  );

  const getStatusColsOrDefault = (status?: Status) =>
    (status && callOffViewSettings.fields.byStatus?.[status]?.overviewList) ||
    callOffViewSettings.fields.default.overviewList;

  const statusConfig: { status?: Status; label: string }[] =
    callOffViewSettings.availableStatus.map((status) => ({
      label: statusDetails[status].label,
      status: status,
    }));
  statusConfig.unshift({
    label: "All",
  });

  const callOffOverviewPageData = useMemo(
    () =>
      statusConfig.map(({ label, status }) => {
        const rows = status
          ? callOffs.filter(
              (callOff) => callOff[callOffViewSettings.statusField || "status"] === status
            )
          : callOffs.filter(
              (callOff) => callOff[callOffViewSettings.statusField || "status"] !== "CANCELED"
            );
        return {
          tabTitle: label,
          tabContent: (
            <ModuleOverviewTable
              rows={rows}
              loading={loading}
              getHref={getCallOffHref}
              columns={getStatusColsOrDefault(status)}
              localStoragePrefix="CallOffTable"
              footer={`Showing ${rows.length} of ${callOffTotalCount} total call-offs`}
            />
          ),
        };
      }),
    [callOffs, callOffViewSettings, loading]
  );

  return (
    <>
      <Helmet>
        <title>Call-Offs | PEK Dashboard</title>
      </Helmet>
      <SusTabBox data={callOffOverviewPageData} />
    </>
  );
};

export default List;

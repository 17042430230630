import React from "react";
import { Grid, TableContainer } from "@mui/material";

import { Helmet } from "react-helmet-async";
import { CallOff } from "@src/types";
import { useDashboardState } from "@src/app.state";
import { generateApiPath, useCurrentCompanyDistributorOrPurchaser } from "@src/utils/helpers";
import SusBox from "@components/SusBox";
import ProductInfoCard from "@components/widgets/ProductInfoCard";
import DropzoneArea from "@components/widgets/DropzoneArea";
import { apiBaseUrl, apiPaths } from "@src/paths";
import ModuleOverviewTable from "@components/ModuleOverviewTable/ModuleOverviewTable";
import CommentBox from "@components/CommentBox/CommentBox";

interface IDetailViewProps {
  callOff?: CallOff;
  loading?: boolean;
}

const CallOffDetailView: React.FC<React.PropsWithChildren<IDetailViewProps>> = ({
  callOff,
  loading: callOffLoading,
  children,
}) => {
  const {
    loading,
    viewSettings: { callOff: callOffViewSettings, callOffPosition: callOffPositionViewSettings },
  } = useDashboardState();

  const companyId = useCurrentCompanyDistributorOrPurchaser(callOff)?.id;

  return (
    <>
      <Helmet>
        {callOff?.contractDistributorReference && (
          <title>{`${callOff?.contractDistributorReference} - Contracts | PEK Dashboard`}</title>
        )}
      </Helmet>
      <SusBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} xl={7}>
            <SusBox>
              <ProductInfoCard
                title={`${callOffViewSettings.titleSingular} Details`}
                data={callOff}
                loading={callOffLoading}
                idGetter={(data) => data?.id}
                lastModifiedAt={callOff?.modifiedTs}
                attrs={callOffViewSettings.fields.default.detailView}
              />
            </SusBox>
          </Grid>
          {!callOffViewSettings.documents?.hide && (
            <Grid item xs={12} md={6} xl={5}>
              <DropzoneArea
                fileAttachments={callOff?.files || []}
                loading={callOffLoading}
                comingSoon={callOffViewSettings.documents?.comingSoon}
                url={generateApiPath(`${apiBaseUrl}${apiPaths.callOffFileUpload}`, {
                  apiVersion: "v1",
                  id: callOff?.id?.toString() || "",
                })}
              />
            </Grid>
          )}
        </Grid>
        {callOff?.callOffPositions && callOff.callOffPositions.length > 0 && (
          <Grid container spacing={3} mt={0.5}>
            <Grid item xs={12} md={12} xl={12}>
              <TableContainer sx={{}}>
                <ModuleOverviewTable
                  rows={callOff.callOffPositions || []}
                  loading={loading}
                  columns={callOffPositionViewSettings.fields.default.childList}
                  localStoragePrefix="CallOffDetailPositionsTable"
                  title={callOffPositionViewSettings.titlePlural}
                />
              </TableContainer>
            </Grid>
          </Grid>
        )}
        {children}
        {callOff?.comments && callOff?.genericRef && (
          <Grid container spacing={3} mt={0.5}>
            <Grid item xs={12} md={12} xl={12}>
              <CommentBox
                title="Comments"
                loading={callOffLoading}
                comments={callOff?.comments || []}
                commentedObjectRef={callOff?.genericRef}
                companyId={companyId}
                distributorId={callOff?.distributor?.id}
              />
            </Grid>
          </Grid>
        )}
      </SusBox>
    </>
  );
};

export default CallOffDetailView;

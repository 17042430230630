import React from "react";
import { ViewSettings } from "@src/types";
import SusTypography from "@components/SusTypography";

const viewSettings: ViewSettings = {
  apiVersion: "v1",
  hasControlRoom: true,
  uiModules: ["Contracts", "Warehousing", "MaterialInTransit", "Upcoming"],
  availableObjectClasses: ["Contract", "ContractPosition"],
  manualObjectCreation: true,
  requestQuoteButton: false,
  tourSteps: [
    // Intro
    {
      content: (
        <>
          <SusTypography variant="h4" fontWeight="bold" mb={"25px"}>
            Welcome to the Dashboard tour
          </SusTypography>
          <SusTypography variant="body1">
            The following steps will give you an overview of the Dashboard.
          </SusTypography>
        </>
      ),
      target: "body",
      // Overlay
      // Navigationselemente
      // Layout
      placement: "center",
    },

    // 1. Site Steps
    // 2. Steps
    {
      content: "Navigate through the contract data here.",
      target: ".SideNavList-wrapper",
      spotlightClicks: true,
      hideBackButton: false,
      disableBeacon: true,
      disableOverlayClose: true,
      disableScrolling: true,
      placement: "right",
    },
    // 4. Steps
    {
      content: "View your profile and adjust notifications and preferences.",
      target: ".userMenu",
      spotlightClicks: false,
      hideBackButton: false,
      disableBeacon: true,
      disableOverlayClose: true,
      disableScrolling: true,
      placement: "bottom",
    },
    // 5. Steps
    {
      content: "Contact support if you need help.",
      target: ".HelpMenue-Wrapper",
      spotlightClicks: false,
      hideBackButton: false,
      disableBeacon: true,
      disableOverlayClose: true,
      disableScrolling: true,
      placement: "right",
    },

    // 2. Site Steps
    // 6. Steps
    {
      content: "Navigate to Control Room.",
      target: ".sidenavItem-control-room",
      nextStepOnClick: true,
      lazyTarget: true,
      spotlightClicks: true,
      hideBackButton: false,
      disableBeacon: true,
      disableOverlayClose: true,
      hideFooter: true,
      disableScrolling: true,
      disableCloseOnEsc: true,
    },
    // 7. Steps
    {
      content: "The default version of the Control Room is displayed here.",
      target: ".ControlRoom-wrapper",
      disableOverlayClose: true,
      disableCloseOnEsc: true,
    },
    // 8. Steps
    {
      content:
        "Design your individual control room here later. Choose from different widgets and data visualisations.",
      target: ".Control-edit-Button",
      spotlightClicks: false,
      disableOverlayClose: true,
      disableCloseOnEsc: true,
    },
    // 3. Site Steps
    // 9. Steps
    {
      content: "Navigate to Contracts.",
      target: ".sidenavItem-contracts",
      nextStepOnClick: true,
      lazyTarget: true,
      spotlightClicks: true,
      hideBackButton: false,
      disableBeacon: true,
      disableOverlayClose: true,
      hideFooter: true,
      disableScrolling: true,
      disableCloseOnEsc: true,
    },
    // 10. Steps
    {
      content: "Filter Contracts by their current status.",
      target: ".Tabs-root-wrapper",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
      disableCloseOnEsc: true,
    },
    // 11. Steps
    {
      content: "Search for Contracts or any detail of a Contract.",
      target: ".SusTabContent .search-input",
      disableScrolling: true,
      disableOverlayClose: true,
      disableCloseOnEsc: true,
    },
    // 12. Steps
    {
      content: "View the most important details of a contract. Sort by any column.",
      target: ".MuiDataGrid-columnHeaders",
      disableScrolling: true,
      disableBeacon: true,
      placement: "bottom",
      disableOverlayClose: true,
      disableCloseOnEsc: true,
    },
    // 13. Steps
    {
      content: "Click on this Contract to view further details.",
      target: ".MuiDataGrid-row:first-of-type",
      nextStepOnClick: true,
      lazyTarget: true,
      spotlightClicks: true,
      hideBackButton: false,
      disableBeacon: true,
      disableOverlayClose: true,
      hideFooter: true,
      disableCloseOnEsc: true,
    },
    // 4. Site Steps
    // 14. Steps
    {
      content: "All details of a Contract are displayed here.",
      target: ".contract-details-card",
      disableBeacon: true,
      disableOverlayClose: true,
      lazyTarget: true,
      placement: "right",
      disableCloseOnEsc: true,
    },
    // 15. Steps
    {
      content: "Time stamp of last data update.",
      target: ".contract-details-card .info-tooltip",
      lazyTarget: false,
      spotlightClicks: true,
      disableOverlayClose: true,
      offset: 10,
      placement: "right",
      disableCloseOnEsc: true,
    },
    // 16. Steps
    {
      content: "Contract documents can be viewed here if attached by your supplier.",
      target: ".product-documents-card",
      lazyTarget: false,
      hideBackButton: false,
      disableBeacon: true,
      disableOverlayClose: true,
      placement: "top",
      disableCloseOnEsc: true,
    },
    // 17. Steps
    {
      content:
        "All positions of Contract - aggregated by their current status - are displayed here. Click to view further details of a certain position. ",
      target: ".wrapper-additional-tables",
      lazyTarget: false,
      hideBackButton: false,
      disableBeacon: true,
      disableOverlayClose: true,
      placement: "top",
      disableCloseOnEsc: true,
    },
    // 18. Steps
    {
      content:
        "Write comments or ask questions on a specific contract here. By tagging (@username) other users you can notify them about your update posted here. ",
      target: ".wrapper-commentbox",
      lazyTarget: false,
      spotlightClicks: true,
      hideBackButton: false,
      disableBeacon: true,
    },
    // 5. Site Steps
    // 19. Steps
    {
      content: "Navigate here to a status of a contract postions",
      target: ".sidenavItem-material-in-transit",
      nextStepOnClick: true,
      lazyTarget: true,
      spotlightClicks: true,
      hideBackButton: false,
      disableBeacon: true,
      disableOverlayClose: true,
      hideFooter: true,
      disableScrolling: true,
      disableCloseOnEsc: true,
    },
    // 20. Steps
    {
      content: "Get a quick overview of the most important numbers on positions in this status.",
      target: ".wrapper-status-overview",
      disableOverlayClose: true,
      placement: "left",
      disableCloseOnEsc: true,
    },
    // 21. Steps
    {
      content: "All positions of various contracts in the selected status are displayed here. ",
      target: ".MuiTableContainer-root",
      disableScrolling: true,
      disableBeacon: true,
      placement: "bottom",
      disableOverlayClose: true,
      disableCloseOnEsc: true,
    },
    // 22. Steps
    {
      content: (
        <>
          <SusTypography variant="h4" fontWeight="bold" mb={"25px"}>
            Well done!
          </SusTypography>
          <SusTypography variant="body1" mb={"10px"}>
            Thank you for taking the tour, we hope you enjoy using the dashboard.
          </SusTypography>
          <SusTypography variant="body2">
            You can restart this tour by clicking Help & Contact in the menu on the left.
          </SusTypography>
        </>
      ),
      target: "body",
      placement: "center",
      hideFooter: false,
    },
  ],
  contract: {
    titleSingular: "Contract",
    titlePlural: "Contracts",
    statusField: "status",
    dataModifiable: true,
    availableStatus: ["OPEN", "COMPLETED"],
    fields: {
      default: {
        detailView: [
          { field: "customerReference", label: "Contract ID" },
          {
            field: "purchaseDate",
            label: "Purchase date",
            type: "date",
            valueGetter: (params) => params.value?.toJSDate(),
          },
          { field: "simpleProducts", label: "Products", type: "list", readonly: true },
          { field: "prms", label: "PRMs", type: "list", readonly: true },
          { field: "packagingEn", label: "Packaging", type: "list", readonly: true },
          { field: "incoterms2020", label: "Incoterms 2020", type: "list", readonly: true },
        ],
        overviewList: [
          { field: "customerReference", headerName: "Contract ID", minWidth: 125 },
          {
            field: "prms",
            headerName: "PRMs",
            type: "list",
          },
          {
            field: "purchaseDate",
            headerName: "Purchase Date",
            minWidth: 150,
            type: "date",
            valueGetter: (params) => params.value?.toJSDate(),
          },
          {
            field: "simpleProducts",
            headerName: "Products",
            minWidth: 200,
            type: "list",
          },
        ],
        childList: [],
      },
    },
  },
  contractPosition: {
    titleSingular: "Contract Position",
    titlePlural: "Contract Positions",
    statusField: "status",
    dataModifiable: true,
    availableStatus: ["UPCOMING", "IN_TRANSIT", "WAREHOUSING", "COMPLETED"],
    fields: {
      default: {
        detailView: [],
        overviewList: [
          {
            field: "contractCustomerReference",
            headerName: "Reference",
            minWidth: 130,
          },
          {
            field: "contractPosition",
            headerName: "Position",
            type: "number",
            width: 80,
          },
          { field: "productSimple", headerName: "Product", minWidth: 200, width: 350 },
          {
            field: "purchasePrice",
            headerName: "Price per ton",
            width: 100,
            type: "currency",
          },
          {
            field: "amount",
            headerName: "Contract tons (MT)",
            width: 140,
            type: "number",
          },
          {
            field: "amountLeft",
            headerName: "Tons left (MT)",
            width: 140,
            type: "number",
          },
        ],
        childList: [],
      },
      byStatus: {
        UPCOMING: {
          detailView: [
            {
              field: "contractCustomerReference",
              label: "Contract ID",
              readonly: true,
              link: {
                objectClass: "Contract",
                fieldRef: "contract",
              },
            },
            { field: "contractPosition", label: "Contract pos." },
            { field: "productSimple", label: "Product" },
            { field: "prm", label: "PRM" },
            { field: "amount", label: "Contract tons (MT)", type: "number" },
            { field: "month", label: "Month" },
            { field: "vesselName", label: "Vessel" },
            {
              field: "laycans",
              label: "Laycans",
              type: "date",
              valueGetter: (params) => params.value?.toJSDate(),
            },
            {
              field: "etaNola",
              label: "ETA nola",
              type: "date",
              valueGetter: (params) => params.value?.toJSDate(),
            },
            { field: "bagSpaceAvailable", label: "Bag space available", type: "number" },
            { field: "bulkSpaceAvailable", label: "Bulk space available", type: "number" },
            { field: "incoterms2020", label: "Incoterms 2020" },
          ],
          overviewList: [
            {
              field: "contractCustomerReference",
              headerName: "Contract Id",
              minWidth: 130,
            },
            {
              field: "contractPosition",
              headerName: "Position",
              type: "number",
              width: 80,
            },
            { field: "month", headerName: "Month", minWidth: 80 },
            { field: "vesselName", headerName: "Vessel name", minWidth: 130 },
            { field: "productSimple", headerName: "Material", minWidth: 200 },
            {
              field: "bagSpaceAvailable",
              headerName: "Bag Space Available",
              minWidth: 180,
              type: "number",
            },
            {
              field: "bulkSpaceAvailable",
              headerName: "Bulk Space Available",
              minWidth: 180,
              type: "number",
            },
            {
              field: "laycans",
              headerName: "Laycans",
              minWidth: 120,
              type: "date",
              valueGetter: (params) => params.value?.toJSDate(),
            },
            {
              field: "etaNola",
              headerName: "ETA nola",
              minWidth: 120,
              type: "date",
              valueGetter: (params) => params.value?.toJSDate(),
            },
          ],
          childList: [
            {
              field: "contractCustomerReference",
              headerName: "Contract Id",
              minWidth: 130,
            },
            {
              field: "contractPosition",
              headerName: "Position",
              type: "number",
              width: 80,
            },
            { field: "month", headerName: "Month", minWidth: 80 },
            { field: "vesselName", headerName: "Vessel name", minWidth: 130 },
            { field: "productSimple", headerName: "Material", minWidth: 200 },
            {
              field: "bagSpaceAvailable",
              headerName: "Bag Space Available",
              minWidth: 180,
              type: "number",
            },
            {
              field: "bulkSpaceAvailable",
              headerName: "Bulk Space Available",
              minWidth: 180,
              type: "number",
            },
            {
              field: "laycans",
              headerName: "Laycans",
              minWidth: 120,
              type: "date",
              valueGetter: (params) => params.value?.toJSDate(),
            },
            {
              field: "etaNola",
              headerName: "ETA nola",
              minWidth: 120,
              type: "date",
              valueGetter: (params) => params.value?.toJSDate(),
            },
          ],
        },
        IN_TRANSIT: {
          detailView: [
            {
              field: "contractCustomerReference",
              label: "Contract ID",
              readonly: true,
              link: {
                objectClass: "Contract",
                fieldRef: "contract",
              },
            },
            { field: "contractPosition", label: "Contract pos." },
            { field: "productSimple", label: "Product" },
            { field: "prm", label: "PRM" },
            { field: "amount", label: "Contract tons (MT)", type: "number" },
            { field: "purchasePrice", label: "Price per ton", type: "currency" },
            { field: "vesselName", label: "Vessel" },
            { field: "imo", label: "IMO" },
            { field: "departureOrigin", label: "Departure origin", type: "date" },
            { field: "etaNola", label: "ETA Nola", type: "date" },
            { field: "etaWarehouse", label: "ETA warehouse", type: "date" },
            { field: "incoterms2020", label: "Incoterms 2020" },
            { field: "bargeLocation", label: "Barge location" },
            { field: "lotBargeVessel", label: "Barge Number" },
          ],
          overviewList: [
            {
              field: "contractCustomerReference",
              headerName: "Contract Id",
              minWidth: 130,
            },
            {
              field: "contractPosition",
              headerName: "Position",
              type: "number",
              width: 80,
            },
            {
              field: "vesselName",
              headerName: "Vessel",
            },
            {
              field: "productSimple",
              headerName: "Product",
              minWidth: 200,
              width: 350,
            },
            {
              field: "amount",
              headerName: "Amount (MT)",
              minWidth: 180,
              type: "number",
            },
            {
              field: "departureOrigin",
              headerName: "Departure origin",
              minWidth: 120,
              type: "date",
              valueGetter: (params) => params.value?.toJSDate(),
            },
            {
              field: "etaNola",
              headerName: "ETA nola",
              width: 120,
              type: "date",
              valueGetter: (params) => params.value?.toJSDate(),
            },
            {
              field: "etaWarehouse",
              headerName: "ETA Warehouse",
              minWidth: 120,
              type: "date",
              valueGetter: (params) => params.value?.toJSDate(),
            },
          ],
          childList: [
            {
              field: "contractCustomerReference",
              headerName: "Contract Id",
              minWidth: 130,
            },
            {
              field: "contractPosition",
              headerName: "Position",
              type: "number",
              width: 80,
            },
            {
              field: "vesselName",
              headerName: "Vessel",
            },
            {
              field: "productSimple",
              headerName: "Product",
              minWidth: 200,
              width: 350,
            },
            {
              field: "amount",
              headerName: "Amount (MT)",
              minWidth: 180,
              type: "number",
            },
            {
              field: "departureOrigin",
              headerName: "Departure origin",
              minWidth: 120,
              type: "date",
              valueGetter: (params) => params.value?.toJSDate(),
            },
            {
              field: "etaNola",
              headerName: "ETA nola",
              width: 120,
              type: "date",
              valueGetter: (params) => params.value?.toJSDate(),
            },
            {
              field: "etaWarehouse",
              headerName: "ETA Warehouse",
              minWidth: 120,
              type: "date",
              valueGetter: (params) => params.value?.toJSDate(),
            },
          ],
        },
        WAREHOUSING: {
          detailView: [
            {
              field: "contractCustomerReference",
              label: "Contract ID",
              readonly: true,
              link: {
                objectClass: "Contract",
                fieldRef: "contract",
              },
            },
            { field: "contractPosition", label: "Contract pos." },
            { field: "productSimple", label: "Product" },
            { field: "prm", label: "PRM" },
            { field: "amount", label: "Contract tons (MT)", type: "number" },
            { field: "amountLeft", label: "Tons left (MT)", type: "number" },
            { field: "purchasePrice", label: "Price per ton", type: "currency" },
            { field: "packagingEn", label: "Packaging" },
            { field: "warhouseLocation", label: "Location" },
            { field: "warehouse", label: "Warehouse" },
            { field: "lotBargeVessel", label: "Lot/Barge/Vessel" },
            { field: "incoterms2020", label: "Incoterms 2020" },
          ],
          overviewList: [
            {
              field: "contractCustomerReference",
              headerName: "Contract Id",
              minWidth: 130,
            },
            {
              field: "contractPosition",
              headerName: "Position",
              type: "number",
              width: 80,
            },
            { field: "productSimple", headerName: "Product", minWidth: 200, width: 350 },
            {
              field: "purchasePrice",
              headerName: "Price per ton",
              width: 100,
              type: "currency",
            },
            { field: "lotBargeVessel", headerName: "Lot / Barge / Vessel", width: 135 },
            { field: "warhouseLocation", headerName: "Location", width: 100 },
            { field: "incoterms2020", headerName: "Incoterms 2020", width: 100 },
            { field: "packagingEn", headerName: "Packaging", width: 100 },
            {
              field: "amount",
              headerName: "Contract tons (MT)",
              width: 140,
              type: "number",
            },
            {
              field: "amountLeft",
              headerName: "Tons left (MT)",
              width: 140,
              type: "number",
            },
          ],
          childList: [
            {
              field: "contractCustomerReference",
              headerName: "Contract Id",
              minWidth: 130,
            },
            {
              field: "contractPosition",
              headerName: "Position",
              type: "number",
              width: 80,
            },
            { field: "productSimple", headerName: "Product", minWidth: 200, width: 350 },
            {
              field: "purchasePrice",
              headerName: "Price per ton",
              width: 100,
              type: "currency",
            },
            { field: "lotBargeVessel", headerName: "Lot / Barge / Vessel", width: 135 },
            { field: "warhouseLocation", headerName: "Location", width: 100 },
            { field: "incoterms2020", headerName: "Incoterms 2020", width: 100 },
            { field: "packagingEn", headerName: "Packaging", width: 100 },
            {
              field: "amount",
              headerName: "Contract tons (MT)",
              width: 140,
              type: "number",
            },
            {
              field: "amountLeft",
              headerName: "Tons left (MT)",
              width: 140,
              type: "number",
            },
          ],
        },
      },
    },
  },
  callOff: {
    titleSingular: "Call-Off",
    titlePlural: "Call-Offs",
    dataModifiable: true,
    statusField: "status",
    availableStatus: [],
    fields: {
      default: {
        detailView: [],
        overviewList: [],
        childList: [],
      },
    },
  },
  callOffPosition: {
    titleSingular: "Call-Off Position",
    titlePlural: "Call-Off Positions",
    dataModifiable: true,
    statusField: null,
    availableStatus: [],
    fields: {
      default: {
        detailView: [],
        overviewList: [],
        childList: [],
      },
    },
  },
};

export default viewSettings;

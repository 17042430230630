import React, { useState } from "react";

import { faArrowAltDown, faEye, faFilePdf, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { ButtonGroup, Popover, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Custom styles.js
import useStyles from "./styles";
import { useTheme } from "@mui/material/styles";
import { useDashboardState } from "@src/app.state";
import SusBox from "@components/SusBox";
import SusTypography from "@components/SusTypography";
import SusButton from "@components/SusButton";
import { File } from "@src/types";

interface IUploadedFileProps {
  file: File;
  onRemoveFile: (file: File) => void;
}

const UploadedFile: React.FC<React.PropsWithChildren<IUploadedFileProps>> = ({
  file,
  onRemoveFile,
}) => {
  const [focussed, setFocussed] = useState<boolean>(false);
  const [confirmDeleteAnchorEl, setConfirmDeleteAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );
  const confirmDeleteOpen = Boolean(confirmDeleteAnchorEl);
  const confirmDeleteId = confirmDeleteOpen ? "confirm-delete-popover" : undefined;

  const { currentDialog, setCurrentDialog } = useDashboardState();

  function closeConfirmDeletePopup() {
    setConfirmDeleteAnchorEl(null);
  }

  const filePathParts = file?.file?.split("/");
  const fileName = filePathParts?.[filePathParts?.length - 1];
  const classes = useStyles();

  const theme = useTheme();

  const handleClose = () => {
    setCurrentDialog(null);
  };

  const onDownload = (url: string) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = "";
    link.click();
  };

  return (
    <>
      {fileName && file?.url && (
        <SusBox
          key={fileName}
          onMouseEnter={() =>
            !confirmDeleteOpen && currentDialog.type !== "ShowDocument" && setFocussed(true)
          }
          onMouseLeave={() =>
            !confirmDeleteOpen && currentDialog.type !== "ShowDocument" && setFocussed(false)
          }
          sx={{
            position: "relative",
            overflow: "hidden",
          }}
        >
          <SusBox display="flex" flexDirection="column" className={classes.documentItem}>
            <SusBox>
              <FontAwesomeIcon className="icon" icon={faFilePdf} />
            </SusBox>
            <SusTypography
              display="block"
              variant="Caption"
              fontWeight="regular"
              noWrap
              sx={{ maxWidth: "100%" }}
            >
              {fileName}
            </SusTypography>
          </SusBox>

          <SusBox
            flexDirection="row"
            className={`${classes.documentContext} ${focussed && "focussed"}`}
          >
            <ButtonGroup variant="outlined" fullWidth>
              <SusButton
                variant="text"
                sx={{ color: theme.palette.secondary.dark }}
                color="primary"
                onClick={() =>
                  setCurrentDialog("ShowDocument", {
                    fileName,
                    onClose: handleClose,
                    fileUrl: file.url,
                  })
                }
              >
                <FontAwesomeIcon icon={faEye} />
              </SusButton>
              <SusButton
                variant="text"
                sx={{ color: theme.palette.secondary.dark }}
                color="success"
                onClick={() => onDownload(file.url || "")}
              >
                <FontAwesomeIcon icon={faArrowAltDown} />
              </SusButton>
              <SusButton
                variant="text"
                sx={{ color: theme.palette.secondary.dark }}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  setConfirmDeleteAnchorEl(event.currentTarget);
                }}
                color="error"
              >
                <FontAwesomeIcon icon={faTrashCan} />
              </SusButton>
            </ButtonGroup>
          </SusBox>
          <Popover
            id={confirmDeleteId}
            open={confirmDeleteOpen}
            onClose={closeConfirmDeletePopup}
            anchorEl={confirmDeleteAnchorEl}
            className={classes.confirmDeleteId}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            sx={{ p: 0 }}
          >
            <SusBox>
              <Typography variant="caption" sx={{ p: 1 }}>
                Delete this file?
              </Typography>
              <SusButton color="primary" onClick={() => onRemoveFile(file)}>
                Yes
              </SusButton>
            </SusBox>
          </Popover>
        </SusBox>
      )}
    </>
  );
};

export default UploadedFile;

import viewConfigEU from "./eu";
import viewConfigEU_STEEL from "./eu_steel";
import viewConfigEUBC from "./eu_bc";
import viewConfigUS from "./us";
import viewConfigNone from "./none";
import { ViewSettings } from "@src/types";

export const availableViewConfigKeys = ["EU", "EU_STEEL", "EUBC", "US", "None"] as const;
export type ViewConfigKey = (typeof availableViewConfigKeys)[number];

export type AvailableViewSettings = Record<ViewConfigKey, ViewSettings>;

const availableViewSettings: AvailableViewSettings = {
  EU: viewConfigEU,
  EU_STEEL: viewConfigEU_STEEL,
  EUBC: viewConfigEUBC,
  US: viewConfigUS,
  None: viewConfigNone,
};

export default availableViewSettings;

import React, { PropsWithChildren, useMemo } from "react";
import { Link } from "react-router-dom";

import { LinearProgressbar } from "./LinearProgressbar";
import Box from "@mui/material/Box";
import { AttrType, LocalStorageKey } from "@src/types";
import { useDashboardState } from "@src/app.state";
import { comparators, formatData } from "@src/utils/typing";
import SusBox from "@components/SusBox";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import DataGrid from "@components/DataGrid/DataGrid";

export interface IModuleOverviewTableProps<ObjectType> {
  rows: ObjectType[];
  loading: boolean;
  getHref?: (row: ObjectType) => any;
  disableLink?: boolean;
  columns: GridColDef[];
  localStoragePrefix: LocalStorageKey;
  title?: string;
  footer?: string;
}

export type TypedGridColDef<ObjectType> = Omit<GridColDef, "field"> & {
  field: keyof ObjectType;
  type?: AttrType;
  progressBar?: boolean;
};

function getAlignFromType(type?: AttrType) {
  return ["number", "decimal", "nonnegativeNumber", "date", "datetime", "currency"].includes(
    type || ""
  )
    ? "right"
    : "left";
}

function ModuleOverviewTable<ObjectType>({
  rows,
  loading,
  getHref,
  disableLink,
  columns,
  localStoragePrefix,
  title,
  footer,
}: PropsWithChildren<IModuleOverviewTableProps<ObjectType>>) {
  const { locale } = useDashboardState();

  const colsWithLink = useMemo(
    () =>
      columns.map((col) => {
        const colType = (col?.type || "text") as AttrType;
        const sortComparator = comparators[colType];
        return {
          renderCell: (params: GridRenderCellParams) => {
            const displayValue = formatData(params.value, colType, locale);
            const href =
              getHref?.(params.row) ||
              ("href" in params.row ? params.row.href.replace(window.location.origin, "") : null);
            const cellContent =
              "progressBar" in col && col?.progressBar ? (
                <LinearProgressbar value={displayValue} />
              ) : (
                <SusBox
                  sx={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                  title={displayValue}
                >
                  {displayValue}
                </SusBox>
              );
            return href && !disableLink ? (
              <Box
                component={Link}
                key={params.id}
                to={href}
                className="DataGridLink"
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                {cellContent}
              </Box>
            ) : (
              cellContent
            );
          },
          sortComparator,
          ...col,
          width: col.width || 100,
          align: col.align || getAlignFromType(col?.type as AttrType),
          headerAlign: col.align || getAlignFromType(col?.type as AttrType),
        };
      }),
    [columns]
  );

  return (
    <SusBox
      sx={{
        background: "#FBFBFB",
        "& .MuiDataGrid-cell--textRight a": {
          justifyContent: "flex-end",
        },
      }}
    >
      <DataGrid
        title={title}
        rows={rows}
        loading={loading}
        columns={colsWithLink}
        localStoragePrefix={localStoragePrefix}
        footer={footer}
      />
    </SusBox>
  );
}

export default ModuleOverviewTable;

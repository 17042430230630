import { useDashboardState } from "@src/app.state";
import React, { useCallback, useEffect, useMemo } from "react";

import { Helmet } from "react-helmet-async";
import { useContracts } from "@hooks/useApiData";
import { getContractHref, paths } from "@src/paths";
import { Status } from "@src/types";
import ModuleOverviewTable from "@components/ModuleOverviewTable/ModuleOverviewTable";
import SusTabBox from "@components/SusTabBox/SusTabBox";

const List: React.FC = () => {
  const {
    setBreadcrumbs,
    viewSettings: { apiVersion, contract: contractViewSettings },
  } = useDashboardState();

  const { data: contracts, totalCount: contractTotalCount, loading } = useContracts(apiVersion);

  useEffect(
    () =>
      setBreadcrumbs([{ label: contractViewSettings.titlePlural, url: paths.contractOverview }]),
    []
  );

  const getStatusColsOrDefault = (status?: Status) =>
    (status && contractViewSettings.fields.byStatus?.[status]?.overviewList) ||
    contractViewSettings.fields.default.overviewList;

  const allContracts = useMemo(() => {
    return (
      <ModuleOverviewTable
        rows={contracts}
        loading={loading}
        getHref={getContractHref}
        columns={getStatusColsOrDefault()}
        localStoragePrefix="ContractTable"
        footer={`Showing ${contracts.length} of ${contractTotalCount} total contracts`}
      />
    );
  }, [contracts, loading, getStatusColsOrDefault, contractTotalCount]);

  const contractsByStatus = useCallback(
    (status: Status, localStoragePrefix: string) => {
      const rows = contracts.filter((contract) => contract.status === status);
      return (
        <ModuleOverviewTable
          rows={rows}
          loading={loading}
          getHref={getContractHref}
          columns={getStatusColsOrDefault(status)}
          localStoragePrefix={localStoragePrefix}
          footer={`Showing ${rows.length} of ${contractTotalCount} total contracts`}
        />
      );
    },
    [contracts, loading, getStatusColsOrDefault, contractTotalCount]
  );

  const contractOverviewPageData = [
    {
      tabTitle: "All",
      tabContent: allContracts,
    },
    {
      status: "OPEN",
      tabTitle: "Open",
      tabContent: contractsByStatus("OPEN", "OpenContractTable"),
    },
    {
      status: "COMPLETED",
      tabTitle: "Completed",
      tabContent: contractsByStatus("COMPLETED", "CompletedContractTable"),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Contracts | PEK Dashboard</title>
      </Helmet>
      <SusTabBox data={contractOverviewPageData} />
    </>
  );
};

export default List;

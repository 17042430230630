// @mui material components
import Card from "@mui/material/Card";

// PEK Dashboard components
import Grid from "@mui/material/Grid";
import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faTimes } from "@fortawesome/pro-regular-svg-icons";
import LoadingButton from "@mui/lab/LoadingButton";
import { cloneDeep, delay } from "lodash";
import { generatePath, Link } from "react-router-dom";
import { Divider, MenuItem, Skeleton, Slide, TextField, Typography } from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers-pro";
import { DateTime } from "luxon";

import { faLink, faPen, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { useDashboardState } from "@src/app.state";
import { formatData, NumberParser } from "@src/utils/typing";
import { DetailFieldDef, ObjectLink } from "@src/types";
import { paths } from "@src/paths";
import { navbarIconButton } from "@components/DashboardNavbar/styles";
import SusWidget from "@src/templates/SusWidget";
import SusBox from "@components/SusBox";
import SusTypography from "@components/SusTypography";
import SusButton from "@components/SusButton";

interface IProductInfoCardProps<ObjectType, IDType> {
  title: string;
  data?: ObjectType;
  idGetter?: (data?: ObjectType) => IDType | undefined;
  lastModifiedAt?: DateTime;
  attrs: DetailFieldDef<ObjectType>[];
  errors?: { [field in keyof ObjectType]: string[] };
  editable?: boolean;
  edit?: boolean;
  onSave?: (data: ObjectType) => void;
  onEdit?: () => void;
  onCancelEdit?: () => void;
  onDelete?: (val: IDType) => void;
  loading?: boolean;
  className?: string;
}

function ProductInfoCard<ObjectType, IDType>({
  title,
  data,
  idGetter,
  lastModifiedAt,
  attrs,
  errors,
  editable,
  edit,
  onSave,
  onEdit,
  onCancelEdit,
  loading,
  onDelete,
  className,
  children,
}: PropsWithChildren<IProductInfoCardProps<ObjectType, IDType>>) {
  const { locale, currentCompany } = useDashboardState();
  const [newData, setNewData] = useState<ObjectType | undefined>();

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);
  const [confirmDiscardOpen, setConfirmDiscardOpen] = useState<boolean>(false);

  const numberParser = useMemo(() => new NumberParser(locale), [locale]);

  useEffect(() => {
    if (data) {
      setNewData(cloneDeep(data));
    } else {
      setNewData(
        Object.keys(attrs).reduce((obj, key) => ({ ...obj, [key]: "" }), {} as ObjectType)
      );
    }
  }, [data]);

  const dataId = useMemo(() => idGetter?.(data), [idGetter, data]);

  const handleInputChange = (key: keyof ObjectType, e: React.ChangeEvent<{ value: unknown }>) => {
    setNewData({
      ...newData,
      [key]: e?.target?.value as string,
    } as ObjectType);
  };

  const handleNumberInputChange = (
    key: keyof ObjectType,
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    const value = numberParser.parse((e?.target?.value as string) || "");
    setNewData({
      ...newData,
      [key]: value,
    } as ObjectType);
  };

  const handleDateInputChange = (key: keyof ObjectType, e: DateTime | null) => {
    setNewData({
      ...newData,
      [key]: e,
    } as ObjectType);
  };

  const getObjectUrl = (link: ObjectLink<any>, data: any) => {
    const fieldValue = data?.[link.fieldRef];
    if (!fieldValue) {
      return "#";
    } else if (link.objectClass === "Contract") {
      return generatePath(paths.contractDetail, { id: fieldValue });
    } else if (link.objectClass === "CallOff") {
      return generatePath(paths.callOffDetail, { id: fieldValue });
    } else if (link.objectClass === "ContractPosition") {
      return generatePath(paths.contractPositionDetail, { id: fieldValue });
    } else {
      return "#";
    }
  };

  // Render the card info items
  const renderItems = useMemo(
    () =>
      attrs &&
      attrs.map(({ field, label, type, readonly, valueGetter, link }) => {
        const value = valueGetter ? valueGetter(data?.[field]) : data?.[field];
        return (
          <Grid item key={field as string} display="flex" xs={12} sm={6} md={4} p={0.5}>
            <Grid container>
              <Card
                sx={{
                  width: "100%",
                  boxShadow: "none",
                  border: "1px solid rgba(34, 34, 34, 0.125)",
                  borderRadius: "13px",
                }}
              >
                <SusBox display="flex" xs={6} p={1} flexDirection="column" sx={{ width: "100%" }}>
                  <SusTypography
                    variant="caption"
                    fontWeight="regular"
                    display="flex"
                    textTransform="uppercase"
                    fontSize="sm"
                  >
                    {label}
                  </SusTypography>
                  {edit && newData && !readonly ? (
                    type === "date" ? (
                      <DatePicker
                        value={newData[field] as any}
                        onChange={(e: DateTime | null) => handleDateInputChange(field, e)}
                        slotProps={{
                          textField: {
                            variant: "standard",
                            sx: {
                              "& .MuiInput-root": {
                                backgroundColor: "transparent !important",
                                border: "none !important",
                                fontSize: "16px",
                              },
                            },
                          },
                        }}
                      />
                    ) : type === "number" ? (
                      <TextField
                        defaultValue={((newData[field] as any) || "").toString()}
                        onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                          handleNumberInputChange(field, e)
                        }
                        inputProps={{ inputMode: "numeric" }}
                        error={errors?.[field] && errors[field].length > 0}
                        helperText={errors?.[field] && errors[field][0]}
                        variant="standard"
                        sx={{
                          "& .MuiInput-root": {
                            backgroundColor: "transparent !important",
                            border: "none !important",
                            fontSize: "16px",
                          },
                        }}
                      />
                    ) : (
                      <TextField
                        defaultValue={newData[field]}
                        onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                          handleInputChange(field, e)
                        }
                        error={errors?.[field] && errors[field].length > 0}
                        helperText={errors?.[field] && errors[field][0]}
                        variant="standard"
                        sx={{
                          "& .MuiInput-root": {
                            backgroundColor: "transparent !important",
                            border: "none !important",
                            fontSize: "16px",
                          },
                        }}
                      />
                    )
                  ) : (
                    <>
                      {link ? (
                        <Link to={getObjectUrl(link, data)}>
                          <SusTypography
                            variant="button"
                            fontWeight="bold"
                            p={0.5}
                            px={0}
                            sx={{ fontSize: "16px", "&:hover": { opacity: 0.7 } }}
                          >
                            {formatData(value, type, locale)}

                            <SusBox ml="0.5rem" display="inline">
                              <FontAwesomeIcon icon={faLink} size="xs" />
                            </SusBox>
                          </SusTypography>
                        </Link>
                      ) : (
                        <SusTypography
                          variant="button"
                          fontWeight="bold"
                          p={0.5}
                          px={0}
                          sx={{ fontSize: "16px" }}
                        >
                          {formatData(value, type, locale)}
                        </SusTypography>
                      )}
                    </>
                  )}
                </SusBox>
              </Card>
            </Grid>
          </Grid>
        );
      }),
    [attrs, edit, data, newData]
  );

  const editButtons = useMemo(
    () =>
      edit &&
      editable && (
        <SusBox display="flex" justifyContent="flex-end" mb={1}>
          {confirmDiscardOpen ? (
            <Slide direction="left" in={confirmDiscardOpen}>
              <SusBox>
                <Typography variant="caption" sx={{ p: 1 }}>
                  Discard changes ?
                </Typography>
                <SusButton
                  color="text"
                  variant="outlined"
                  size="medium"
                  sx={{
                    mt: { xs: 1, md: 0 },
                    mr: { xs: 1, sm: 1, md: 1 },
                    ml: "auto",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    minWidth: "auto",
                  }}
                  onClick={() => {
                    delay(() => setConfirmDiscardOpen(false), 200);
                  }}
                  ml={1}
                >
                  No
                </SusButton>
                <SusButton
                  color="primary"
                  size="medium"
                  sx={{
                    mt: { xs: 1, md: 0 },
                    mr: { xs: 1, sm: 1, md: 1 },
                    ml: "auto",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    minWidth: "auto",
                  }}
                  onClick={() => {
                    delay(() => setConfirmDiscardOpen(false), 200);
                    setNewData(cloneDeep(data));
                    onCancelEdit?.();
                  }}
                >
                  Yes
                </SusButton>
              </SusBox>
            </Slide>
          ) : (
            <SusBox display="flex" justifyContent="flex-end">
              <SusButton
                size="medium"
                color="text"
                variant="outlined"
                sx={{
                  lineHeight: "1",
                  mt: { xs: 1, md: 0 },
                  mr: { xs: 1, sm: 1, md: 1 },
                  ml: "auto",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  minWidth: "auto",
                }}
                onClick={() => {
                  setConfirmDiscardOpen(true);
                }}
              >
                <SusBox mr="0.5rem" color="text" display="inlineFlex">
                  <FontAwesomeIcon icon={faTimes} size="xs" />
                </SusBox>
                cancel
              </SusButton>
              <LoadingButton
                variant="contained"
                color="primary"
                loading={loading}
                size="medium"
                sx={{
                  lineHeight: "1",
                  mt: { xs: 1, md: 0 },
                  mr: { xs: 1, sm: 1, md: 1 },
                  ml: "",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  minWidth: "auto",
                }}
                onClick={async () => newData && onSave?.(newData)}
              >
                <SusBox mr="0.5rem" color="white" display="inlineFlex">
                  <FontAwesomeIcon icon={faFloppyDisk} size="xs" />
                </SusBox>
                Save
              </LoadingButton>
            </SusBox>
          )}
        </SusBox>
      ),
    [edit, editable, data, newData, confirmDiscardOpen, onSave]
  );

  const menuItems =
    editable && ["admin", "data_editor"].includes(currentCompany?.role || "")
      ? (closeMenu: () => void) => (
          <div>
            <MenuItem onClick={closeMenu}>
              <SusButton
                variant="text"
                sx={navbarIconButton}
                size="small"
                color="dark"
                startIcon={<FontAwesomeIcon icon={faPen} />}
                fullWidth
                onClick={onEdit}
              >
                Edit
              </SusButton>
            </MenuItem>
            {onDelete && <Divider sx={{ margin: "0.5rem 0" }} />}
            {confirmDeleteOpen && onDelete && idGetter ? (
              <Slide direction="left" in={confirmDeleteOpen}>
                <MenuItem>
                  <Typography variant="caption" sx={{ p: 1 }}>
                    Delete this {title}?
                  </Typography>
                  <SusButton
                    color="primary"
                    onClick={() => {
                      delay(() => setConfirmDeleteOpen(false), 700);
                      closeMenu();
                      dataId && onDelete(dataId);
                    }}
                  >
                    Yes
                  </SusButton>
                </MenuItem>
              </Slide>
            ) : onDelete && idGetter ? (
              <MenuItem color="error" onClick={() => setConfirmDeleteOpen(true)}>
                <SusButton
                  variant="text"
                  sx={navbarIconButton}
                  size="small"
                  color="error"
                  startIcon={<FontAwesomeIcon icon={faTrashCan} />}
                  fullWidth
                >
                  Delete
                </SusButton>
              </MenuItem>
            ) : null}
          </div>
        )
      : undefined;

  return (
    <SusWidget
      title={title}
      loading={loading}
      infoTooltip={`Last updated ${formatData(lastModifiedAt, "datetime", locale)}`}
      actions={editButtons || undefined}
      menuItems={menuItems}
      className={`${className} wrapper-${title.toLowerCase().replace(/\s/g, "")}-Widget`}
      onMenuClose={() => delay(() => setConfirmDeleteOpen(false), 700)}
    >
      <Grid container mb={1}>
        {!loading ? children : null}
        {loading ? (
          <Grid container spacing={1}>
            <Grid item display="flex" xs={6} md={4} flexDirection="column">
              <Skeleton
                variant="rectangular"
                width={210}
                height={60}
                sx={{ borderRadius: "13px" }}
              />
            </Grid>
            <Grid item display="flex" xs={6} md={4} flexDirection="column">
              <Skeleton
                variant="rectangular"
                width={210}
                height={60}
                sx={{ borderRadius: "13px" }}
              />
            </Grid>
            <Grid item display="flex" xs={6} md={4} flexDirection="column">
              <Skeleton
                variant="rectangular"
                width={210}
                height={60}
                sx={{ borderRadius: "13px" }}
              />
            </Grid>
            <Grid item display="flex" xs={6} md={4} flexDirection="column">
              <Skeleton
                variant="rectangular"
                width={210}
                height={60}
                sx={{ borderRadius: "13px" }}
              />
            </Grid>
            <Grid item display="flex" xs={6} md={4} flexDirection="column">
              <Skeleton
                variant="rectangular"
                width={210}
                height={60}
                sx={{ borderRadius: "13px" }}
              />
            </Grid>
            <Grid item display="flex" xs={6} md={4} flexDirection="column">
              <Skeleton
                variant="rectangular"
                width={210}
                height={60}
                sx={{ borderRadius: "13px" }}
              />
            </Grid>
          </Grid>
        ) : (
          renderItems
        )}
      </Grid>
    </SusWidget>
  );
}

export default ProductInfoCard;

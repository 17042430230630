import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Grid, IconButton, TableContainer } from "@mui/material";

import { isEmpty } from "lodash";

import useAxios from "axios-hooks";

import { GridColDef } from "@mui/x-data-grid-pro";

import { Helmet } from "react-helmet-async";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/pro-solid-svg-icons";
import Divider from "@mui/material/Divider";
import { apiBaseUrl, apiPaths, getCallOffHref, getContractPositionHref, paths } from "@src/paths";
import { useDashboardState } from "@src/app.state";
import useAlerts from "@hooks/useAlerts";
import useApiData from "@hooks/useApiData";
import { generateApiPath, useCurrentCompanyDistributorOrPurchaser } from "@src/utils/helpers";
import {
  Contract,
  ContractPosition,
  ContractPositionStatus,
  ProductReorderingSpecs,
} from "@src/types";
import { deserializeContract, serializeContract } from "@src/utils/typing";
import dashboardAxios from "@src/api";
import ModuleOverviewTable from "@components/ModuleOverviewTable/ModuleOverviewTable";
import SusBox from "@components/SusBox";
import ProductInfoCard from "@components/widgets/ProductInfoCard";
import SusWidget from "@src/templates/SusWidget";
import SusTypography from "@components/SusTypography";
import DropzoneArea from "@components/widgets/DropzoneArea";
import CommentBox from "@components/CommentBox/CommentBox";
import { sendMatomoEvent } from "@src/features/tracking";

const ContractDetailView: React.FC = () => {
  const overviewUrl = paths.contractOverview;
  const {
    currentCompany,
    loading,
    setBreadcrumbs,
    setCurrentDialog,
    viewSettings: {
      apiVersion,
      contract: contractViewSettings,
      contractPosition: contractPositionViewSettings,
      callOff: callOffViewSettings,
    },
  } = useDashboardState();

  const { id } = useParams();
  const navigate = useNavigate();
  const alerts = useAlerts();
  const {
    data: rawContract,
    initialLoading: contractLoading,
    error: contractError,
  } = useApiData(generateApiPath(apiPaths.contractDetail, { apiVersion, id }));

  const [, patchData] = useAxios(
    {
      url: generateApiPath(apiPaths.contractDetail, { apiVersion, id }),
      method: "patch",
      withCredentials: true,
    },
    {
      manual: true,
    }
  );
  const [errors, setErrors] = useState<{ [field: string]: string[] }>({});
  const [contract, setContract] = useState<Contract | undefined>();
  const [edit, setEdit] = useState(false);

  // Set breadcrumbs
  useEffect(
    () =>
      setBreadcrumbs([
        { label: contractViewSettings.titlePlural, url: paths.contractOverview },
        {
          label: contract?.distributorReference || "...",
        },
      ]),
    [contract]
  );

  const companyId = useCurrentCompanyDistributorOrPurchaser(contract)?.id;

  useEffect(() => {
    if (rawContract) {
      setContract(deserializeContract(rawContract));
    }
  }, [rawContract]);

  const filteredPositionsUpcoming = useMemo(
    () =>
      contract?.contractPositions &&
      contract.contractPositions.filter((pos) => pos.status === "UPCOMING"),
    [contract?.contractPositions]
  );

  const filteredPositionsInTransit = useMemo(
    () =>
      contract?.contractPositions &&
      contract.contractPositions.filter((pos) => pos.status === "IN_TRANSIT"),
    [contract?.contractPositions]
  );

  const filteredPositionsWarehousing = useMemo(
    () =>
      contract?.contractPositions &&
      contract.contractPositions.filter((pos) => pos.status === "WAREHOUSING"),
    [contract?.contractPositions]
  );

  const filteredPositionsOther = useMemo(
    () =>
      contract?.contractPositions &&
      contract.contractPositions.filter(
        (pos) =>
          !(
            ["UPCOMING", "IN_TRANSIT", "WAREHOUSING"] as (ContractPositionStatus | undefined)[]
          ).includes(pos.status)
      ),
    [contract?.contractPositions]
  );

  const filteredCallOffs = useMemo(
    () => contract?.callOffs && contract.callOffs.filter((co) => co.status !== "CANCELED"),
    [contract?.callOffs]
  );

  useEffect(() => {
    if (!contractError) {
      return;
    }
    alerts.notifyError({
      title: "Not found",
      content:
        contractError?.response?.status === 404
          ? `Could not find contract with id ${id}.`
          : "Sorry, something went wrong. Please try again later.",
      autoHideDuration: 5000,
    });
    navigate(overviewUrl);
  }, [contractError]);

  const handleSave = async (newData: Contract) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { purchaser, distributor, ...cleanedNewData } = newData;
    const serializedContract = serializeContract(cleanedNewData);
    return patchData({ data: serializedContract })
      .then((response) => {
        if (response?.data) {
          setContract(deserializeContract(response.data));
          setEdit(false);
        }
      })
      .catch((error) => {
        setErrors(error?.response?.data || {});
        if (error?.response?.data?.detail || isEmpty(error?.response?.data)) {
          const errorMsg = error?.response?.data?.detail;
          alerts.notifyError({
            title: "Error",
            content: errorMsg || "Sorry, something went wrong. Please try again later.",
            autoHideDuration: 5000,
          });
        }
      });
  };

  const handleEdit = () => setEdit(true);
  const handleCancelEdit = () => setEdit(false);

  const handleDelete = async (id: number) =>
    dashboardAxios
      .delete(generateApiPath(apiPaths.contractDetail, { apiVersion: "v1", id: id.toString() }))
      .then((response) => {
        if (response.status === 204) {
          navigate(overviewUrl);
          alerts.notifySuccess({
            title: "Success",
            content: `Successfully deleted contract with id ${id}.`,
            autoHideDuration: 5000,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          alerts.notifyError({
            title: "Error",
            content: "You don't have permission to delete this contract.",
            autoHideDuration: 5000,
          });
        } else {
          alerts.notifyError({
            title: "Error",
            content: "Sorry, something went wrong. Please try again later.",
            autoHideDuration: 5000,
          });
        }
      });

  const getContractPositionsTable = (
    localStorageKey: string,
    cols: GridColDef[],
    title: string,
    positions?: ContractPosition[]
  ) =>
    positions?.length ? (
      <Grid
        container
        spacing={3}
        mt={0.5}
        className={`wrapper-card-Contracts wrapper-${title.toLowerCase().replace(/\s/g, "")}-table`}
      >
        <Grid item xs={12} md={12} xl={12}>
          <TableContainer sx={{}}>
            <ModuleOverviewTable
              rows={positions || []}
              loading={loading}
              columns={cols}
              getHref={getContractPositionHref}
              disableLink={contractPositionViewSettings.disableDetailView}
              localStoragePrefix={localStorageKey}
              title={title}
            />
          </TableContainer>
        </Grid>
      </Grid>
    ) : null;

  const reorderingProducts =
    contract?.contractPositions?.map((pos) => {
      const productQuality =
        pos?.productPurityMin && pos?.productPurityMax
          ? `${pos?.productPurityMin} - ${pos?.productPurityMax}`
          : pos?.productPurityMin || pos?.productPurityMax || "";

      const productSize =
        pos?.productSizeMin && pos?.productSizeMax
          ? `${pos?.productSizeMin} - ${pos?.productSizeMax} ${pos?.productSizeUnit || ""}`
          : `${pos?.productSizeMin || pos?.productSizeMax || ""} ${pos?.productSizeUnit || ""}`;

      return {
        posId: pos.contractPosition,
        name: pos?.productNameDe,
        amount: `${pos?.amount} ${pos?.amountUnit}`,
        productQuality,
        productSize,
        packaging: pos?.packagingEn,
      } as ProductReorderingSpecs;
    }) || [];

  return (
    <>
      <Helmet>
        {contract?.distributorReference && (
          <title>{`${contract?.distributorReference} -  Contracts | PEK Dashboard`}</title>
        )}
      </Helmet>
      <SusBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} xl={7}>
            <SusBox>
              <ProductInfoCard
                title={`${contractViewSettings.titleSingular} Details`}
                className={"contract-details-card"}
                data={contract}
                loading={contractLoading}
                idGetter={(data) => data?.id}
                errors={errors}
                lastModifiedAt={contract?.modifiedTs}
                attrs={contractViewSettings.fields.default.detailView}
                editable={
                  contractViewSettings.dataModifiable &&
                  ["admin", "data_editor"].includes(currentCompany?.role || "")
                }
                edit={edit}
                onEdit={handleEdit}
                onSave={handleSave}
                onCancelEdit={handleCancelEdit}
                onDelete={handleDelete}
              />
            </SusBox>
          </Grid>
          {currentCompany?.companySalesRepEmail ? (
            <Grid item xs={12} md={6} xl={5}>
              <SusWidget
                title={""}
                loading={loading}
                sx={{
                  background: "#619D8E",
                  paddingTop: "0",
                }}
              >
                <SusBox sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton sx={{ mr: 1, color: "#fff" }}>
                    <FontAwesomeIcon icon={faInfo} />
                  </IconButton>
                  <SusTypography variant="h6" sx={{ color: "#fff" }}>
                    Need new material?
                  </SusTypography>
                </SusBox>
                <Divider sx={{ mb: 1 }} />
                <SusBox sx={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}>
                  <SusTypography variant="h6" sx={{ color: "#fff" }}>
                    Your stock of material in this contract is running low?
                    <br /> If you want to place a new request, you can do so here.
                  </SusTypography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setCurrentDialog("ReOrderingDialog", {
                        products: reorderingProducts,
                        incoterms: contract?.incoterms,
                        incotermsCity: contract?.incotermsCity,
                        contractId: contract?.id,
                        contractDistributorReference: contract?.distributorReference,
                      });
                      sendMatomoEvent("contract", "reorder", "contract_detail");
                    }}
                    sx={{
                      display: "flex",
                      mt: 1,
                      backgroundColor: "#fff",
                      color: "#619D8E",
                      "&:hover": {
                        backgroundColor: "#fff",
                        color: "#619D8E",
                      },
                      "&:focus:not(:hover)": {
                        backgroundColor: "#fff",
                        color: "#619D8E",
                      },
                    }}
                  >
                    Request new quote
                  </Button>
                </SusBox>
              </SusWidget>
            </Grid>
          ) : null}
          {!contractViewSettings.documents?.hide && (
            <Grid item xs={12} md={6} xl={5}>
              <DropzoneArea
                fileAttachments={rawContract?.files}
                loading={contractLoading}
                comingSoon={contractViewSettings.documents?.comingSoon}
                url={generateApiPath(`${apiBaseUrl}${apiPaths.contractFileUpload}`, {
                  apiVersion: "v1",
                  id,
                })}
              />
            </Grid>
          )}
        </Grid>
        <SusBox className="wrapper-additional-tables">
          {getContractPositionsTable(
            "ContractDetailPositionsUpcomingTable",
            contractPositionViewSettings.fields.byStatus?.["UPCOMING"]?.childList ||
              contractPositionViewSettings.fields.default.childList,
            "Upcoming Positions",
            filteredPositionsUpcoming
          )}
          {getContractPositionsTable(
            "ContractDetailPositionsInTransitTable",
            contractPositionViewSettings.fields.byStatus?.["IN_TRANSIT"]?.childList ||
              contractPositionViewSettings.fields.default.childList,
            "Positions in transit",
            filteredPositionsInTransit
          )}
          {getContractPositionsTable(
            "ContractDetailPositionsWarehousingTable",
            contractPositionViewSettings.fields.byStatus?.["WAREHOUSING"]?.childList ||
              contractPositionViewSettings.fields.default.childList,
            "Positions in warehouses",
            filteredPositionsWarehousing
          )}
          {getContractPositionsTable(
            "ContractDetailPositionsGeneralTable",
            contractPositionViewSettings.fields.default.childList,
            contractPositionViewSettings.titlePlural,
            filteredPositionsOther
          )}
        </SusBox>
        {filteredCallOffs?.length ? (
          <Grid container spacing={3} mt={0.5} className={"wrapper-callOffs-table"}>
            <Grid item xs={12} md={12} xl={12}>
              <TableContainer sx={{}}>
                <ModuleOverviewTable
                  rows={filteredCallOffs || []}
                  loading={loading}
                  columns={callOffViewSettings.fields.default.childList}
                  getHref={getCallOffHref}
                  localStoragePrefix={"ContractDetailCallOffsGeneralTable"}
                  title={callOffViewSettings.titlePlural}
                />
              </TableContainer>
            </Grid>
          </Grid>
        ) : null}
        <Grid container spacing={3} mt={0.5}>
          <Grid item xs={12} md={12} xl={12}>
            <CommentBox
              title="Comments"
              loading={contractLoading}
              comments={contract?.comments || []}
              commentedObjectRef={contract?.genericRef}
              companyId={companyId}
              distributorId={contract?.distributor?.id}
            />
          </Grid>
        </Grid>
      </SusBox>
    </>
  );
};

export default ContractDetailView;
